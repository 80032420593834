.showmodal {
  display: block;
  padding-right: 17px;
}
.modal-dialog-centered .btn {
  font-weight: 600;
}
.modal {
  z-index: 99999;
  overflow: hidden;
}
.modal-body {
  padding: 2.5em 3em;
}
h4.modal-header-title {
  font-size: 4em;
  text-align: center;
  margin: 1rem 0 1em 0;
  font-weight: 600;
}
.btn.pop-login {
  border-radius: 50px;
  padding: 20px 0;
  background: #fd5332;
  border-color: #fd5332;
  margin-top: 0.6rem;
}
.modal-divider {
  position: relative;
  margin: 20px 0;
  text-align: center;
}
.modal-divider:before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e1e5f2;
}
.modal-divider span {
  position: relative;
  background: #fff;
  padding: 0 20px;
}
.social-login ul {
  margin: 0;
  width: 100%;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-login ul li {
  display: inline-block;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 50%;
  width: 50%;
  list-style: none;
  padding: 0 10px;
}
.social-login ul li a.btn {
  width: 100%;
  border-radius: 5px;
  padding: 20px 0;
  color: #ffffff;
  background: #f4f5f7;
}
.social-login ul li a.btn i {
  margin-right: 7px;
}
.social-login ul li a.btn.connect-fb {
  background: #3b5998;
}
.social-login ul li a.btn.connect-google {
  background: #ec4514;
}
.social-login ul li a.btn.connect-linkedin {
  background: #0073b0;
}
.social-login ul li a.btn.connect-twitter {
  background: #20a4ea;
}
.signup .modal-dialog {
  max-width: 880px;
  z-index: +999999;
}
.signup .form-group {
  margin-bottom: 1.5rem;
}
.modal-dialog-centered .btn {
  font-weight: 600;
}
span.mod-close {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 13px;
  color: #fd5332;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
  -webkit-box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
}
.fade {
  transition: opacity 0.15s linear;
}
.input-with-icon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
  color: #a2a9bf;
  font-style: normal;
  cursor: normal;
  pointer-events: none;
}
.input-with-icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  cursor: normal;
}
.btn-tertiary{
  color: #2445cd !important;
}
.my-input{
  border-radius: 8px;
  border: 1px solid #E1E9F5;
}
.my-input input{
  border-radius: 8px !important;
  background: #F3F7FD !important;
}
