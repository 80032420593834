ul.know_more li a {
  width: 100%;
  color: #005b69 !important;
  border: 1px solid #005b69;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 14px;
}

ul.know_more li {
  margin: 1rem;
}

.block-wrap {
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 40px 0 0 4px;
  padding-top: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.block-wraps {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 30px;
  overflow: hidden;
}
.block-wrap:last-child {
  margin-bottom: 0px;
}
.block-wraps .block-header {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #08184b;
  background: #08184b;
}
.block-header {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e8eef7;
}
.nav-tabs .nav-link {
  margin-bottom: 1px;
}
.block-wraps-header .block-body {
  padding: 1.5rem;
}
.block-header .block-title {
  font-size: 16px;
}
.block-header.ags .block-title {
  flex: 1;
  margin: 0;
  line-height: 0;
}
.block-wraps-header .block-body {
  padding: 1.5rem;
}
.block-body {
  line-height: 1.7;
  display: inline-block;
  width: 100%;
}
.property_block_wrap.style-2 .block-body {
  padding: 1rem 3.1rem 2rem;
}
.sider-block-body label {
  font-size: 13px;
  color: #282d3e;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.dw-proprty-info {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dw-proprty-info li {
  display: block;
  list-style: none;
  position: relative;
  margin: 6px 0;
  color: #005b69;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 14px;
}
.dw-proprty-info li strong {
  display: block;
  color: #2a3c77;
  font-size: 16px;
}
@media (max-width: 1199px) {
  .dw-proprty-info li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.property_block_wrap.style-2 .property_block_wrap_header h4 {
  margin: 24px 24px 0 24px !important;
  font-weight: 700;
  font-size: 24px;
  font-family: 'Manrope', sans-serif !important;
  color: #140c40;
}
.block-header .block-title {
  font-size: 20px;
}
