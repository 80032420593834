.be-grid{
    height: auto !important;

}
.team-grid label{
    font-family: 'Manrope';
    margin-bottom: 8px;
}
.team-grid .simple{
    font-family: 'Manrope';
    border-radius: 8px !important;
}
.contact-info{
    margin-left: 2rem;
    margin-top: 1rem;
}
.contact-info h2, h4{
    font-size: 20px;
}
.contact-info p{
    font-size: 14px;
    margin-bottom: 2rem;
    
}
.cn-info-content{
font-size: 14px;
}
.cn-info-detail{
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 2rem;
}
.ti-home{
    fill: #005b69;
}
.btn-primary{
    background: #005b69;
    border: #005b69;
    padding: 12px 40px;
    border-radius: 12px !important;
}
.btn-primary:hover{
    background: #337c87;
    border: #005b69;
}