.cookie{
    position: fixed;
  bottom: 1rem;
  z-index: 2051;
    border-radius: 99999px;
    padding: 1rem;
    background: #F3F5F5;
    box-shadow: 0px 4px 8px -2px #E8EBEC;
}
.flex{
    display: flex;
}  
.flex-row{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.cookie h4{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    margin-left: 2rem;
}
.cookie p{
    font-size: 0.9rem;
    margin: 0 1rem;
    width: 50%;
    line-height: 1.4rem;
    text-align: center;
}
.cookie button{
    margin-right: 2rem;
}
.d-none{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}