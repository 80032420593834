/*------------------------------------
	Fonts
------------------------------------*/
@import url(./assets/plugins/animation.css);
@import url(./assets/plugins/slick.css);
@import url(./assets/plugins/slick-theme.css);
@import url(./assets/plugins/select2.css);
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&amp;display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

* {
  outline: none;
}
/*------------------------------------------------------------
	GLobal Settings
-------------------------------------------------------------*/
body {
  background: #ffffff;
  color: #4e5c79;
  font-size: 16px;
  font-family: 'Manrope', sans-serif !important;
  margin: 0;
  overflow-x: hidden !important;
  font-weight: 400;
}
.modal-open {
  overflow: hidden !important;
}
html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}
.form-group {
  margin-bottom: 15px;
}
a {
  color: #2d3954;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  /* clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap; */
}

.dark #preloader {
  background-color: #232323;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 99;
}
.form-group {
  margin-bottom: 15px;
}
.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #00a94f;
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
form.dropzone.dz-clickable.primary-dropzone {
  background: #f9fafc;
  border: 1px solid #ebedf5;
}
i.req {
  color: #e41515;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: #fd5332;
}
a.link:hover,
a.link:focus,
a:hover,
a:focus {
  color: #fd5332;
}
a.text-success:focus,
a.text-success:hover {
  color: #0fca98 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #fd5332 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ff9b20 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #f33066 !important;
}
a.white-link {
  color: #ffffff;
}
a.white-link:hover,
a.white-link:focus {
  color: #263238;
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}
section {
  padding: 80px 0 80px;
}
section.min {
  padding: 50px 0 50px;
}
p {
  line-height: 1.8;
}

.gray-bg {
  background: #f7f9fc url(./assets/images/pattern.png);
  background-size: cover;
  background-position: center;
}
.gray {
  background: #f7f9fc;
}
.gray-simple {
  background: #f3f7fd;
}
.bg-title {
  background: #2540a2;
}
.bg-1 {
  background: #f7d0cb;
}
.bg-2 {
  background: #dde9ed;
}
.tbl {
  display: table;
}
.full-height {
  height: 100%;
}
.rtl {
  position: relative;
}
.mr-2 {
  margin-right: 8px;
}
.mr-1 {
  margin-right: 4.5px;
}
.alert p {
  margin: 0;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.no-ul-list {
  padding: 0;
}
.no-ul-list li {
  list-style: none;
}
.progress {
  margin-bottom: 1.5em;
}
.full-width {
  width: 100%;
}
p,
ul,
ol,
dl,
dt,
dd,
blockquote,
address {
  margin: 0 0 10px;
}
.owl-carousel .item {
  padding: 0 15px;
}
.explore-content p {
  font-size: 19px;
  font-family: 'Manrope', sans-serif !important;
  font-style: italic;
}
.explore-content h1,
.explore-content h2 {
  font-family: 'Manrope', sans-serif !important;
  font-weight: 600;
}
.shadow-0 {
  box-shadow: none !important;
}
/*------------ GLobal Settings: Heading-------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e2638;
  font-weight: 700;
  text-transform: capitalize;
  font-family: 'Manrope', sans-serif !important;
}

.font-2 {
  font-family: 'Manrope', sans-serif !important;
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 24px;
}

h4 {
  line-height: 26px;
  font-size: 21px;
}

h5 {
  line-height: 22px;
  font-size: 18px;
}

h6 {
  line-height: 20px;
  font-size: 16px;
}
.lead {
  font-size: 20px !important;
}
.lead-i {
  font-family: 'Manrope', sans-serif !important;
  font-size: 22px !important;
  font-style: italic;
}
.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.display-7 {
  font-size: 26px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.circle {
  border-radius: 100%;
}

.theme-cl {
  color: #fd5332;
}
.theme-bg {
  background: #fd5332;
}
/*------------ Global Settings: Ul List Style ----------------*/
ul.list-style {
  padding: 0;
  margin: 0;
}
ul.list-style li {
  margin-bottom: 1.2em;
  line-height: 1.5;
  list-style: none;
  padding-left: 30px;
  position: relative;
}
ul.list-style li:before {
  content: '\e64c';
  position: absolute;
  left: 0;
  font-size: 16px;
  top: 0;
  color: #27b737;
  font-family: 'themify';
}
ul.list-style.style-2 li:before {
  content: '\e628' !important;
  color: #0273ff;
}

/*------------ Global Settings: Cutom Height ----------------*/
.ht-10 {
  height: 10px;
}
.ht-20 {
  height: 20px;
}
.ht-30 {
  height: 30px;
}
.ht-40 {
  height: 40px;
}
.ht-50 {
  height: 50px;
}
.ht-60 {
  height: 60px;
}
.ht-70 {
  height: 70px;
}
.ht-80 {
  height: 80px;
}
.ht-80 {
  height: 80px;
}
.ht-100 {
  height: 100px;
}
.ht-110 {
  height: 110px;
}
.ht-120 {
  height: 120px;
}
.ht-130 {
  height: 130px;
}
.ht-140 {
  height: 140px;
}
.ht-150 {
  height: 150px;
}
.ht-160 {
  height: 160px;
}
.ht-170 {
  height: 170px;
}
.ht-180 {
  height: 180px;
}
.ht-190 {
  height: 190px;
}
.ht-200 {
  height: 200px;
}
.ht-100 {
  height: 100%;
  min-height: 580px;
}
.h-100 {
  height: 100vh;
  min-height: 580px;
}
/*------------ Global Settings: Typography ----------------*/
.type--fade {
  opacity: 0.5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.85714286em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: 0.5;
}
.type--underline {
  text-decoration: underline;
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
blockquote {
  font-style: italic;
}
blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}
/*------------ Global Settings: Common & List ----------------*/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
  margin-left: 0;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 0.5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
.color--white {
  color: #fff !important;
}
.color--dark {
  color: #252525 !important;
}
.color--success {
  color: #4ebf56 !important;
}
.color--error {
  color: #e23636 !important;
}
.bg--dark {
  background: #1f2025 !important;
}
.bg--dark:not(.nav-bar):not(.bar) {
  color: #ffffff;
}
.bg--dark .boxed:not([class*='bg-']),
.imagebg:not(.image--light) .boxed:not([class*='bg-']),
.bg--dark .large-features-2,
.bg--dark .pr-table-box,
.bg--dark .veticle-large-features-box,
.bg--dark .large-features-box,
.bg--dark .pricing-wrap {
  background: none;
  color: #fff;
}
.bg--dark .boxed:not([class*='bg-']).boxed--border,
.imagebg:not(.image--light) .boxed:not([class*='bg-']).boxed--border {
  border-color: #2a2b31;
}

.bg--dark:not(.nav-bar):not(.bar) h1,
.bg--dark:not(.nav-bar):not(.bar) h2,
.bg--dark:not(.nav-bar):not(.bar) h3,
.bg--dark:not(.nav-bar):not(.bar) h4,
.bg--dark:not(.nav-bar):not(.bar) h5,
.bg--dark:not(.nav-bar):not(.bar) h6,
.bg--dark:not(.nav-bar):not(.bar) i,
.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text),
.bg--dark:not(.nav-bar):not(.bar) p {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #ffffff;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--primary .color--primary {
  color: #fff !important;
}
.bg--light {
  background: #fff;
}
.bg--light p,
.bg--light span,
.bg--light ul,
.bg--light a:not(.btn) {
  color: #666666;
}
.bg--light h1,
.bg--light h2,
.bg--light h3,
.bg--light h4,
.bg--light h5,
.bg--light h6,
.bg--light i {
  color: #252525;
}
.bg--error {
  background: #e23636;
}
.bg--success {
  background: #4ebf56;
}
.imagebg:not(.image--light) .bg--light p,
.imagebg:not(.image--light) .bg--light span,
.imagebg:not(.image--light) .bg--light ul,
.imagebg:not(.image--light) .bg--light a:not(.btn) {
  color: #666666;
}
.imagebg:not(.image--light) .bg--light h1,
.imagebg:not(.image--light) .bg--light h2,
.imagebg:not(.image--light) .bg--light h3,
.imagebg:not(.image--light) .bg--light h4,
.imagebg:not(.image--light) .bg--light h5,
.imagebg:not(.image--light) .bg--light h6,
.imagebg:not(.image--light) .bg--light i {
  color: #252525;
}
.imagebg:not(.image--light) .bg--secondary {
  background: rgba(250, 250, 250, 0.2);
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998 !important;
}
.color--twitter {
  color: #00aced !important;
}
.color--googleplus {
  color: #dd4b39 !important;
}
.color--instagram {
  color: #125688 !important;
}
.color--pinterest {
  color: #cb2027 !important;
}
.color--dribbble {
  color: #ea4c89 !important;
}
.color--behance {
  color: #053eff !important;
}
.color--linkedin {
  color: #1685b9 !important;
}
.bg--facebook {
  background: #3b5998 !important;
  color: #fff !important;
}
.bg--twitter {
  background: #00aced !important;
  color: #fff !important;
}
.bg--googleplus {
  background: #dd4b39 !important;
  color: #fff !important;
}
.bg--instagram {
  background: #125688 !important;
  color: #fff !important;
}
.bg--pinterest {
  background: #cb2027 !important;
  color: #fff !important;
}
.bg--dribbble {
  background: #ea4c89 !important;
  color: #fff !important;
}
.bg--behance {
  background: #053eff !important;
  color: #fff !important;
}
.bg--linkedin {
  background: #1685b9 !important;
  color: #fff !important;
}
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
/*------------ Global Settings: Background & Image ----------------*/
.bg-img-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  opacity: 0;
  background: #252525;
}
.bg-img-holder:not([class*='col-']) {
  width: 100%;
}
.bg-img-holder.background--bottom {
  background-position: 50% 100% !important;
}
.bg-img-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .bg-img-holder {
  background: none;
}
.bg-img-holder img {
  display: none;
}
.imagebg.border--round {
  overflow: hidden;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay] *:not(.container):not(.bg-img-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #4a90e2;
}
[data-overlay='1']:before {
  opacity: 0.1;
}
[data-overlay='2']:before {
  opacity: 0.2;
}
[data-overlay='3']:before {
  opacity: 0.3;
}
[data-overlay='4']:before {
  opacity: 0.4;
}
[data-overlay='5']:before {
  opacity: 0.5;
}
[data-overlay='6']:before {
  opacity: 0.6;
}
[data-overlay='7']:before {
  opacity: 0.7;
}
[data-overlay='8']:before {
  opacity: 0.8;
}
[data-overlay='9']:before {
  opacity: 0.9;
}
[data-overlay='10']:before {
  opacity: 1;
}
[data-overlay='0']:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, #252525)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom='1']:before {
  opacity: 0.1;
}
[data-scrim-bottom='2']:before {
  opacity: 0.2;
}
[data-scrim-bottom='3']:before {
  opacity: 0.3;
}
[data-scrim-bottom='4']:before {
  opacity: 0.4;
}
[data-scrim-bottom='5']:before {
  opacity: 0.5;
}
[data-scrim-bottom='6']:before {
  opacity: 0.6;
}
[data-scrim-bottom='7']:before {
  opacity: 0.7;
}
[data-scrim-bottom='8']:before {
  opacity: 0.8;
}
[data-scrim-bottom='9']:before {
  opacity: 0.9;
}
[data-scrim-bottom='10']:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    left bottom,
    color-stop(0%, #252525),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    bottom,
    #252525 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top='1']:before {
  opacity: 0.1;
}
[data-scrim-top='2']:before {
  opacity: 0.2;
}
[data-scrim-top='3']:before {
  opacity: 0.3;
}
[data-scrim-top='4']:before {
  opacity: 0.4;
}
[data-scrim-top='5']:before {
  opacity: 0.5;
}
[data-scrim-top='6']:before {
  opacity: 0.6;
}
[data-scrim-top='7']:before {
  opacity: 0.7;
}
[data-scrim-top='8']:before {
  opacity: 0.8;
}
[data-scrim-top='9']:before {
  opacity: 0.9;
}
[data-scrim-top='10']:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2.35714286em;
}
.icon--lg {
  font-size: 5.57142857em;
}
.imagebg:not(.image--light) span {
  color: #fff;
}
/*------------ GLobal Settings: Blockquote -------------*/
.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}

/*------------ GLobal Settings: Call To Action -------------*/
.bg-cover {
  background-size: cover !important;
  background-position: center !important;
}
.image-bg {
  background-size: cover !important;
  background-position: center !important;
}
.default-bg {
  background-size: initial !important;
  background-position: initial !important;
}
.image-bg-wrap {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}
.image-bg-wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #182538;
  display: block;
  content: '';
  opacity: 0.8;
}

/*-------------- GLobal Settings: Paddings ----------------*/
html body .p-0 {
  padding: 0px;
}

html body .p-10 {
  padding: 10px;
}

html body .p-15 {
  padding: 15px;
}

html body .p-20 {
  padding: 20px;
}

html body .p-30 {
  padding: 30px;
}

html body .p-40 {
  padding: 40px;
}

html body .p-l-0 {
  padding-left: 0px;
}

html body .p-l-10 {
  padding-left: 10px;
}

html body .p-l-20 {
  padding-left: 20px;
}

html body .p-r-0 {
  padding-right: 0px;
}

html body .p-r-10 {
  padding-right: 10px;
}

html body .p-r-20 {
  padding-right: 20px;
}

html body .p-r-30 {
  padding-right: 30px;
}

html body .p-r-40 {
  padding-right: 40px;
}

html body .p-t-0 {
  padding-top: 0px;
}

html body .p-t-10 {
  padding-top: 10px;
}

html body .p-t-20 {
  padding-top: 20px;
}

html body .p-t-30 {
  padding-top: 30px;
}

html body .p-b-0 {
  padding-bottom: 0px;
}

html body .p-b-5 {
  padding-bottom: 5px;
}

html body .p-b-10 {
  padding-bottom: 10px;
}

html body .p-b-20 {
  padding-bottom: 20px;
}

html body .p-b-30 {
  padding-bottom: 30px;
}

html body .p-b-40 {
  padding-bottom: 40px;
}

/*-------------- GLobal Settings: Margin ----------------*/
html body .mr-auto {
  margin: auto;
}

html body .m-0 {
  margin: 0px;
}

html body .m-l-5 {
  margin-left: 5px;
}

html body .m-l-10 {
  margin-left: 10px;
}

html body .m-l-15 {
  margin-left: 15px;
}

html body .m-l-20 {
  margin-left: 20px;
}

html body .m-l-30 {
  margin-left: 30px;
}

html body .m-l-40 {
  margin-left: 40px;
}

html body .m-r-5 {
  margin-right: 5px;
}

html body .m-r-10 {
  margin-right: 10px;
}

html body .m-r-15 {
  margin-right: 15px;
}

html body .m-r-20 {
  margin-right: 20px;
}

html body .m-r-30 {
  margin-right: 30px;
}

html body .m-r-40 {
  margin-right: 40px;
}

html body .m-t-0 {
  margin-top: 0px;
}

html body .m-t-5 {
  margin-top: 5px;
}

html body .m-t-10 {
  margin-top: 10px;
}

html body .m-t-15 {
  margin-top: 15px;
}

html body .m-t-20 {
  margin-top: 20px;
}

html body .m-t-30 {
  margin-top: 30px;
}

html body .m-t-40 {
  margin-top: 40px;
}

html body .m-b-0 {
  margin-bottom: 0px;
}

html body .m-b-5 {
  margin-bottom: 5px;
}

html body .m-b-10 {
  margin-bottom: 10px;
}

html body .m-b-15 {
  margin-bottom: 15px;
}

html body .m-b-20 {
  margin-bottom: 20px;
}

html body .m-b-30 {
  margin-bottom: 30px;
}

html body .m-b-40 {
  margin-bottom: 40px;
}

/*------------ GLobal Settings: vertical alignment -------------*/
html body .vt {
  vertical-align: top;
}

html body .vm {
  vertical-align: middle;
}

html body .vb {
  vertical-align: bottom;
}

/*-------------- GLobal Settings: Opacity ----------------*/
.op-8 {
  opacity: 0.8;
}

.op-7 {
  opacity: 0.7;
}

.op-5 {
  opacity: 0.5;
}

.op-3 {
  opacity: 0.3;
}

/*-------------- GLobal Settings: font weight ----------------*/
html body .font-bold {
  font-weight: 600;
}

html body .font-normal {
  font-weight: normal;
}

html body .font-light {
  font-weight: 300;
}

html body .font-medium {
  font-weight: 500;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-13 {
  font-size: 13px;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

/*-------------- GLobal Settings: Border ----------------*/
html body .b-0 {
  border: none !important;
}

html body .b-r {
  border-right: 1px solid #e0ecf5 !important;
}

html body .b-l {
  border-left: 1px solid #e0ecf5 !important;
}

html body .b-b {
  border-bottom: 1px solid #e0ecf5 !important;
}

html body .b-t {
  border-top: 1px solid #e0ecf5 !important;
}

html body .b-all {
  border: 1px solid #e0ecf5 !important;
}

/*-------------- GLobal Settings: Thumb size ----------------*/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 100%;
}

.radius {
  border-radius: 4px;
}

/*-------------- GLobal Settings: Text Colors ----------------*/
.text-white {
  color: #ffffff !important;
}

.text-danger {
  color: #f33066 !important;
}

.text-muted {
  color: #8d97ad !important;
}

.text-warning {
  color: #ff9b20 !important;
}

.text-success {
  color: #0fca98 !important;
}

.text-info {
  color: #fd5332 !important;
}

.text-inverse {
  color: #3e4555 !important;
}

html body .text-blue {
  color: #1d96ff;
}

html body .text-purple {
  color: #7460ee;
}

html body .text-primary {
  color: #fd5332 !important;
}

html body .text-megna {
  color: #1dc8cd;
}

html body .text-dark {
  color: #8d97ad;
}

html body .text-themecolor {
  color: #fd5332;
}

/*-------------- Global Settings: Alerts & Notification --------------*/
.alert-primary {
  color: #0055ff;
  background-color: #eaf1ff;
  border-color: #eaf1ff;
}
.alert-success {
  color: #27b737;
  background-color: #e9ffeb;
  border-color: #e9ffeb;
}
.alert-warning {
  color: #ff9b20;
  background-color: #fff5e9;
  border-color: #fff5e9;
}
.alert-info {
  color: #08a7c1;
  background-color: #effdff;
  border-color: #effdff;
}
.alert-danger {
  color: #f33066;
  background-color: #ffe9e9;
  border-color: #ffe9e9;
}
.alert-dark {
  color: #3e4758;
  background-color: #eff4ff;
  border-color: #eff4ff;
}
.alert-secondary {
  color: #4b5d6f;
  background-color: #d6dfe8;
  border-color: #d6dfe8;
}
/*-------------- GLobal Settings: Background Colors ----------------*/
.bg-primary {
  background-color: #fd5332 !important;
}

.bg-success {
  background-color: #27b737 !important;
}

.bg-info {
  background-color: #1ac790 !important;
}

.bg-warning {
  background-color: #ff9b20 !important;
}

.bg-danger {
  background-color: #f33066 !important;
}

.bg-orange {
  background-color: #ec2828 !important;
}

.bg-yellow {
  background-color: #fed700;
}

.bg-facebook {
  background-color: #3b5a9a;
}

.bg-twitter {
  background-color: #56adf2;
}

html body .bg-megna {
  background-color: #1dc8cd;
}

html body .bg-theme {
  background-color: #fd5332;
}

html body .bg-inverse {
  background-color: #374158;
}

html body .bg-purple {
  background-color: #7460ee;
}

html body .bg-orange {
  background-color: #fff6f5 !important;
}

html body .bg-light {
  background-color: #f5faff !important;
}

html body .bg-light-primary {
  background-color: #f1effd;
}

html body .bg-light-success {
  background-color: #e8fdeb;
}

html body .bg-light-info {
  background-color: #cfecfe;
}

html body .bg-light-extra {
  background-color: #ebf3f5;
}

html body .bg-light-warning {
  background-color: #fff8ec;
}

html body .bg-light-danger {
  background-color: #f9e7eb;
}

html body .bg-light-inverse {
  background-color: #f6f6f6;
}

html body .bg-light {
  background-color: #f5f7f9;
}

html body .bg-white {
  background-color: #ffffff;
}

html body .bg-whites {
  background-color: #f4f5f7;
}

html body .bg-red {
  background-color: #e21137;
}

html body .bg-green {
  background-color: #4caf50;
}

html body .bg-sky {
  background-color: #20b2c1;
}

html body .bg-blue {
  background-color: #03a9f4;
}

html body .bg-dark-blue {
  background-color: #192c48;
}
/*-------------- GLobal Settings: Rounds ----------------*/
.round {
  line-height: 48px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border-radius: 100%;
  background: #fd5332;
}
.round img {
  border-radius: 100%;
}

.round-lg {
  line-height: 65px;
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.round.round-info {
  background: #fd5332;
}

.round.round-warning {
  background: #ff9b20;
}

.round.round-danger {
  background: #f33066;
}

.round.round-success {
  background: #27b737;
}

.round.round-primary {
  background: #fd5332;
}

/*----------- GLobal Settings: Simple List -----------*/
ul.simple-list {
  padding: 0;
  margin: 0;
}
ul.simple-list li {
  list-style: none;
  padding: 10px 5px 10px 28px;
}
ul.simple-list li {
  list-style: none;
  padding: 10px 5px 10px 28px;
  position: relative;
}
ul.simple-list li:before {
  content: '\e6af';
  font-family: themify;
  position: absolute;
  left: 0;
}

/*-------------- GLobal Settings: Labels ----------------*/
.label {
  padding: 3px 15px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.label-rounded {
  border-radius: 60px;
}

.label-custom {
  background-color: #1dc8cd;
}

.label-success {
  background-color: #27b737;
}

.label-info {
  background-color: #fd5332;
}

.label-warning {
  background-color: #ff9b20;
}

.label-danger {
  background-color: #f33066;
}

.label-megna {
  background-color: #1dc8cd;
}

.label-primary {
  background-color: #fd5332;
}

.label-purple {
  background-color: #7460ee;
}

.label-red {
  background-color: #fb3a3a;
}

.label-inverse {
  background-color: #3e4555;
}

.label-default {
  background-color: #f4f8fa;
}

.label-white {
  background-color: #ffffff;
}

.label-light-success {
  background-color: #e8fdeb;
  color: #27b737;
}

.label-light-info {
  background-color: #cfecfe;
  color: #fd5332;
}

.label-light-warning {
  background-color: #fff8ec;
  color: #ff9b20;
}

.label-light-danger {
  background-color: #f9e7eb;
  color: #f33066;
}

.label-light-megna {
  background-color: #e0f2f4;
  color: #1dc8cd;
}

.label-light-primary {
  background-color: #f1effd;
  color: #fd5332;
}

.label-light-inverse {
  background-color: #f6f6f6;
  color: #3e4555;
}

/*------------ GLobal Settings: Table ------------*/
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid transparent;
  border-top: 0px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f9fb;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #f7f9fb;
}
.table tr th,
.table tr td {
  border-color: #eaeff5;
  padding: 12px 15px;
  vertical-align: middle;
}
.table.tbl-big tr th,
.table.tbl-big tr td {
  padding: 20px 15px;
}
.table.tbl-big.center tr th,
.table.tbl-big.center tr td {
  padding: 20px 15px;
  text-align: center;
}
table.table tr th {
  font-weight: 600;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e !important;
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
/*------------- GLobal Settings: Badge ----------------*/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #27b737;
}

.badge-info {
  background-color: #fd5332;
}

.badge-primary {
  background-color: #fd5332;
}

.badge-warning {
  background-color: #ff9b20;
}

.badge-danger {
  background-color: #f33066;
}

.badge-purple {
  background-color: #7460ee;
}

.badge-red {
  background-color: #fb3a3a;
}

.badge-inverse {
  background-color: #3e4555;
}

/*------------- GLobal Settings: List-style-none ----------------*/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}

ul.list-style-none li {
  list-style: none;
}

ul.list-style-none li a {
  color: #8d97ad;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
  font-size: 15.5px;
}
ul.list-style-none li a:hover {
  color: #fd5332;
}

/*------------- GLobal Settings: dropdown ----------------*/
.dropdown-item {
  padding: 8px 1rem;
  color: #607394;
  font-size: 15.5px;
}

/*------------- GLobal Settings: Buttons ----------------*/
.btn {
  border: 2px solid transparent;
}
.btn {
  color: #ffffff;
  padding: 12px 25px;
  cursor: pointer;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  border-radius: 0.1rem;
}
a.btn-trans-video {
  font-size: 1em;
  padding: 0.8em 1.6em;
  color: #fd5332;
}
a.btn-trans-video i {
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  background: #fd5332;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0px 0px 0px 12px rgb(179, 220, 255);
  -webkit-box-shadow: 0px 0px 0px 12px rgb(179, 220, 255);
}
.light-vid a.btn-trans-video {
  color: #ffffff;
}
.light-vid a.btn-trans-video i {
  background: #3583ff;
  color: #ffffff;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.5);
}
.btn:hover {
  color: #ffffff;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #263238;
  padding: 10px 15px;
}

.btn-link .underline {
  border-bottom: 1px solid #263238;
}

.btn-link:hover {
  color: #27b737;
}

.btn-light {
  color: #263238;
}

.btn-light:hover {
  background: #fd5332;
  color: #ffffff;
  border-color: #fd5332;
}

.btn-lg {
  padding: 1.2em 2em;
  font-size: 1em;
}

.btn-md {
  padding: 1em 1.5em;
  font-size: 1em;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 70px;
  height: 70px;
  padding: 24px 15px;
  font-size: 20px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
}

.btn-arrow {
  position: relative;
}

.btn-arrow span {
  display: inline-block;
  position: relative;
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: transform;
}

.btn-arrow:hover span,
.btn-arrow:focus span {
  -webkit-transform: translate3d(-1rem, 0, 0);
  transform: translate3d(-1rem, 0, 0);
}

.btn-arrow i {
  position: absolute;
  width: 1.1em;
  right: 0px;
  right: 0rem;
  opacity: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  will-change: right, opacity;
}

.btn-arrow:hover i,
.btn-arrow:focus i {
  opacity: 1;
  right: -2rem;
}
.btn-theme {
  background: #fd5332;
  border-color: #fd5332;
  color: #ffffff;
  transition: all ease 0.4s;
}
.btn-theme-2 {
  background: #3151b7;
  border-color: #3151b7;
  color: #ffffff;
  transition: all ease 0.4s;
}
.btn-theme-2:hover,
.btn-theme-2:focus {
  background: #fd5332;
  border-color: #fd5332;
  color: #ffffff !important;
}
.btn-outline-theme {
  color: #fd5332;
  background-color: transparent;
  border: 2px solid #fd5332;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-theme:hover,
.btn-outline-theme:focus {
  color: #ffffff !important;
  background-color: #fd5332;
  border-color: #fd5332;
}
.btn-outline-theme-2 {
  color: #3151b7;
  background-color: transparent;
  border: 2px solid #3151b7;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.btn-outline-theme-2:hover,
.btn-outline-theme-2:focus {
  color: #ffffff;
  background-color: #3151b7;
  border-color: #3151b7;
}
.btn-secondary,
.btn-secondary.disabled {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color: #eef0f3;
  color: #8d97ad;
}

.btn-secondary:hover,
.btn-secondary.disabled:hover {
  color: #ffffff !important;
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
  color: #ffffff !important;
  background: #263238;
  border-color: #263238;
}

.btn-primary,
.btn-primary.disabled {
  background: #fd5332;
  border: 1px solid #fd5332;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-primary:hover,
.btn-primary.disabled:hover {
  background: #2677e6;
  border: 1px solid #2677e6;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
  background: #345bcb;
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #fd5332;
  color: #ffffff;
  border: 1px solid #fd5332;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #028ee1;
  border: 1px solid #028ee1;
}
.btn-black {
  background: #263547;
  color: #ffffff;
  border: 1px solid #263547;
}

.btn-black:hover,
.btn-black:focus {
  background: #0f7dff;
  border: 1px solid #0f7dff;
}

.btn-themecolor.active,
.btn-themecolor:active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

.btn-success,
.btn-success.disabled {
  background: #27b737;
  border: 1px solid #27b737;
  color: #ffffff;
}

.btn-success:hover,
.btn-success.disabled:hover {
  background: #1fd628;
  border: 1px solid #1fd628;
}

.btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus {
  background: #1fd628;
}

.btn-info,
.btn-info.disabled {
  background: #1ac790;
  border: 1px solid #1ac790;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-info:hover,
.btn-info.disabled:hover {
  background: #1ac790;
  border: 1px solid #1ac790;
}

.btn-info.active,
.btn-info:active,
.btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus {
  background: #0976ea;
}

.btn-warning,
.btn-warning.disabled {
  background: #ff9b20;
  color: #ffffff;
  border: 1px solid #ff9b20;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
  background: #f1901a;
  color: #ffffff;
  border: 1px solid #f1901a;
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
  background: #f1901a;
  color: #ffffff;
}

.btn-danger,
.btn-danger.disabled {
  background: #f33066;
  border: 1px solid #f33066;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-danger:hover,
.btn-danger.disabled:hover {
  background: #d73e6b;
  border: 1px solid #d73e6b;
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
  background: #d73e6b;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #3e4555;
  border: 1px solid #3e4555;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse.disabled:hover {
  background: #232a37;
  color: #ffffff;
  border: 1px solid #232a37;
}

.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
  background: #232a37;
  color: #ffffff;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}

.btn-red:hover,
.btn-red.disabled:hover {
  border: 1px solid #d61f1f;
  background: #d61f1f;
}

.btn-red.active,
.btn-red:active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus {
  background: #d73e6b;
}

.btn-success-gradiant {
  background: #27b737;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #27b737 0%,
    #1fd628 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#27b737),
    to(#1fd628)
  );
  background: -webkit-linear-gradient(left, #27b737 0%, #1fd628 100%);
  background: -o-linear-gradient(left, #27b737 0%, #1fd628 100%);
  background: linear-gradient(to right, #27b737 0%, #1fd628 100%);
  border: 0px;
}

.btn-success-gradiant:hover {
  background: #1fd628;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #1fd628 0%,
    #27b737 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1fd628),
    to(#27b737)
  );
  background: -webkit-linear-gradient(left, #1fd628 0%, #27b737 100%);
  background: -o-linear-gradient(left, #1fd628 0%, #27b737 100%);
  background: linear-gradient(to right, #1fd628 0%, #27b737 100%);
}

.btn-success-gradiant.active,
.btn-success-gradiant:active,
.btn-success-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-danger-gradiant {
  background: #f33066;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #f33066 0%,
    #ec2828 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f33066),
    to(#ec2828)
  );
  background: -webkit-linear-gradient(left, #f33066 0%, #ec2828 100%);
  background: -o-linear-gradient(left, #f33066 0%, #ec2828 100%);
  background: linear-gradient(to right, #f33066 0%, #ec2828 100%);
  border: 0px;
}

.btn-danger-gradiant:hover {
  background: #ec2828;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ec2828 0%,
    #f33066 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ec2828),
    to(#f33066)
  );
  background: -webkit-linear-gradient(left, #ec2828 0%, #f33066 100%);
  background: -o-linear-gradient(left, #ec2828 0%, #f33066 100%);
  background: linear-gradient(to right, #ec2828 0%, #f33066 100%);
}

.btn-danger-gradiant.active,
.btn-danger-gradiant:active,
.btn-danger-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-warning-gradiant {
  background: #ff9b20;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff9b20 0%,
    #f48624 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff9b20),
    to(#f48624)
  );
  background: -webkit-linear-gradient(left, #ff9b20 0%, #f48624 100%);
  background: -o-linear-gradient(left, #ff9b20 0%, #f48624 100%);
  background: linear-gradient(to right, #ff9b20 0%, #f48624 100%);
  border: 0px;
}

.btn-warning-gradiant:hover {
  background: #f48624;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #f48624 0%,
    #ff9b20 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f48624),
    to(#ff9b20)
  );
  background: -webkit-linear-gradient(left, #f48624 0%, #ff9b20 100%);
  background: -o-linear-gradient(left, #f48624 0%, #ff9b20 100%);
  background: linear-gradient(to right, #f48624 0%, #ff9b20 100%);
}

.btn-warning-gradiant.active,
.btn-warning-gradiant:active,
.btn-warning-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-info-gradiant,
.btn-primary-gradiant {
  background: #fd5332;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #fd5332 0%,
    #fd5332 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd5332),
    to(#fd5332)
  );
  background: -webkit-linear-gradient(left, #fd5332 0%, #fd5332 100%);
  background: -o-linear-gradient(left, #fd5332 0%, #fd5332 100%);
  background: linear-gradient(to right, #fd5332 0%, #fd5332 100%);
  border: 0px;
  color: #ffffff;
}

.btn-info-gradiant:hover,
.btn-primary-gradiant:hover {
  background: #fd5332;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #fd5332 0%,
    #fd5332 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd5332),
    to(#fd5332)
  );
  background: -webkit-linear-gradient(left, #fd5332 0%, #fd5332 100%);
  background: -o-linear-gradient(left, #fd5332 0%, #fd5332 100%);
  background: linear-gradient(to right, #fd5332 0%, #fd5332 100%);
}

.btn-info-gradiant.active,
.btn-info-gradiant:active,
.btn-info-gradiant:focus {
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
  opacity: 1;
}

.btn-outline-secondary {
  background-color: #ffffff;
  color: #727b84;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  background: #263238;
}

.btn-outline-primary {
  color: #fd5332;
  background-color: #ffffff;
  border-color: #fd5332;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  background: #fd5332;
  color: #ffffff;
  border-color: #fd5332;
}
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background: #345bcb;
}

.btn-outline-success,
a.btn-outline-success {
  color: #27b737;
  background-color: transparent;
  border-color: #27b737;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success.focus,
a.btn-outline-success:hover,
a.btn-outline-success:focus,
a.btn-outline-success.focus {
  background: #27b737;
  border-color: #27b737;
  color: #ffffff;
}

.btn-outline-success.active,
.btn-outline-success:active,
.btn-outline-success:focus,
a.btn-outline-success.active,
a.btn-outline-success:active,
a.btn-outline-success:focus {
  background: #1fd628;
}

.btn-outline-info {
  color: #1ac790;
  background-color: transparent;
  border-color: #1ac790;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
  background: #1ac790;
  border-color: #1ac790;
  color: #ffffff;
}

.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
  background: #1ac790;
}

.btn-outline-warning {
  color: #ff9b20;
  background-color: transparent;
  border-color: #ff9b20;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  background: #ff9b20;
  border-color: #ff9b20;
  color: #ffffff;
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
  background: #f1901a;
}

.btn-outline-danger {
  color: #f33066;
  background-color: transparent;
  border-color: #f33066;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  background: #f33066;
  border-color: #f33066;
  color: #ffffff;
}
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
  background: #d73e6b;
}

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a;
}

.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
  background: #fb3a3a;
  border-color: #fb3a3a;
  color: #ffffff;
}

.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
  background: #d73e6b;
}

.btn-outline-inverse {
  color: #3e4555;
  background-color: transparent;
  border-color: #3e4555;
}

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
  background: #3e4555;
  border-color: #3e4555;
  color: #ffffff;
}

.btn-outline-light:hover {
  color: #263238 !important;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #345bcb;
  border: 1px solid #345bcb;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #1fd628;
  border: 1px solid #1fd628;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color: #1ac790;
  border: 1px solid #1ac790;
  color: #ffffff;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #f1901a;
  border: 1px solid #f1901a;
  color: #ffffff;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #d73e6b;
  border: 1px solid #d73e6b;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
  color: #ffffff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.btn span.btn-devider {
  display: inline-block;
  padding-left: 10px;
}
.btn-warning-light {
  color: #ff8f00 !important;
  background: rgba(255, 143, 0, 0.1);
  border-color: rgba(0, 0, 0, 0);
}
.btn-warning-light:hover,
.btn-warning-light:focus {
  color: #ffffff !important;
  background: #ff8f00;
  border-color: #ff8f00;
}
.btn-success-light {
  color: #29cf8d !important;
  background: rgba(41, 207, 141, 0.1);
  border-color: rgba(0, 0, 0, 0);
}
.btn-success-light:hover,
.btn-success-light:focus {
  color: #ffffff !important;
  background: #29cf8d;
  border-color: #29cf8d;
}
.btn-primary-light {
  color: #fd5332 !important;
  background: rgba(51, 137, 255, 0.1);
  border-color: rgba(0, 0, 0, 0);
}
.btn-primary-light:hover,
.btn-primary-light:focus {
  color: #ffffff !important;
  background: #fd5332;
  border-color: #fd5332;
}
.btn-danger-light {
  color: #ed2929 !important;
  background: rgba(237, 41, 41, 0.1);
  border-color: rgba(0, 0, 0, 0);
}
.btn-danger-light:hover,
.btn-danger-light:focus {
  color: #ffffff !important;
  background: #ed2929;
  border-color: #ed2929;
}
.btn-over-light {
  color: #ffffff !important;
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
.btn-over-light:hover,
.btn-over-light:focus {
  color: #ffffff !important;
  background: #ff8f00;
  border-color: rgba(255, 255, 255, 0.2);
}
/*----------- GLobal Settings: Video ------------------*/
.cover .video-block:not(:first-child):not(:last-child) {
  margin: 2.77em 0;
}
.video-play-icon {
  width: 6.42857143em;
  height: 6.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  line-height: 6em;
  background: #ffffff;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
}
.video-play-icon a {
  font-size: 3em;
  color: #fd5332;
}
/*----------- GLobal Settings: Pagination ------------------*/
.pagination {
  display: table;
  padding-left: 0;
  border-radius: 4px;
  margin: 20px auto 0 !important;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 0;
  margin: 5px;
  color: #5a6f7c;
  text-decoration: none;
  background-color: #fff;
  border-radius: 2px;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  border: 1px solid #eaeff5;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover,
.pagination > li > a:focus,
.pagination > li > a:hover {
  z-index: 2;
  color: #ffffff;
  cursor: pointer;
  background-color: #27ae60;
  border-color: #27ae60;
}

.pagination li:first-child a {
  background: #fd5332;
  border: 1px solid #fd5332;
  border-radius: 2px;
  color: #fff;
}

.pagination li:last-child a {
  background: #35434e;
  border: 1px solid #35434e;
  border-radius: 2px;
  color: #fff;
}
.pagination > li {
  display: inline;
}
.page-item {
  cursor: pointer;
}
.page-item.active .page-link {
  cursor: pointer;
  z-index: 2;
  color: #fff;
  background-color: #fd5332;
  border-color: #fd5332;
}
/*----------- Global Settings: Custom Checkbox & Radio Button ----------------*/
.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: flex;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #fff;
  border: 2px solid #dae3ec;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.radio-custom + .radio-custom-label:before {
  content: '';
  background: #fff;
  border: 2px solid #dae3ec;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: '\e64c';
  font-family: 'Themify';
  background: #140c40 !important;
  border-radius: 2px;
  box-shadow: none;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '\e64c';
  font-family: 'Themify';
  width: 20px;
  height: 20px;
  background: #140c40 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #ffffff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  background: #140c40 !important;
  border: 1px solid #140c40;
  box-shadow: inset 0px 0px 0px 4px #140c40;
}
.company-brands label {
  font-weight: normal;
  color: #828f99;
  font-size: 15px;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
  outline: none; /* focus style */
}
.company-brands input[type='checkbox']:focus {
  outline: none;
}
.company-brands label:focus {
  outline: none;
}
.filter_wraps [type='radio']:checked + label:after,
.filter_wraps [type='radio']:not(:checked) + label:after {
  right: 0px;
  left: auto;
}
/*--------------- GLobal Settings: On off switch ---------------*/
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: '';
  padding-right: 27px;
  background-color: #27b737;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: '';
  padding-right: 24px;
  background-color: #3e4555;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #ffffff;
  height: 23px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 35px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
/*----------- GLobal Settings: Custom Radio Button ------------*/
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  width: 10px;
  height: 10px;
  background: #fd5332;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/*------------- GLobal Settings: Bootstrap 4 hack --------------*/
.card-columns {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.card-columns .card {
  margin-bottom: 30px;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
  .nav-left-side ul.attributes {
    float: none;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*---------- GLobal Settings: Section Heading Style ---------*/
.sec-heading {
  margin-bottom: 2rem;
}
.sec-heading.lg {
  margin-bottom: 30px;
}
.sec-heading.center {
  text-align: center;
}
.sec-heading h2,
.sec-heading p {
  margin-bottom: 0px;
}
.sec-heading.light h2,
.sec-heading.light p {
  color: #ffffff;
}
.sec-heading p {
  margin-bottom: 4px;
  line-height: 1.4;
  font-size: 14px;
  margin-top: 16px;
}
.single-items {
  padding: 0 10px;
}
.property-slide,
.item-slide {
  margin: 0 -15px;
}
.sec-heading2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sec-left {
  flex: 1;
}
.sec-left h3 {
  font-size: 20px;
  margin-bottom: 0px;
}
.sec-right a {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
}
/*------------ GLobal Settings: All Forms Style ---------------*/
.form-control {
  height: 56px;
  font-size: 16px;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e0ecf5;
  background-clip: initial;
}
.submit-form .form-control,
.submit-page .form-control,
.form-submit .form-control {
  background: #f9fafc;
  border: 1px solid #ebedf5;
  border-radius: 3px;
  color: #646e77;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 15px;
  padding-left: 15px;
}

.form-control.simple {
  background: #f2f3f5;
  border-color: #f2f3f5;
  border-radius: 4px;
}
.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border-radius: 0;
  border: none;
  height: 57px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #626a70;
  line-height: 57px;
  text-align: left;
  padding: 0 15px 0 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  position: absolute;
  top: 1px;
  right: 10px;
  width: 20px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #6877a5;
}
.full-search-2 .form-control::-webkit-input-placeholder {
  color: #6877a5;
}
.full-search-2 .form-control:-ms-input-placeholder {
  color: #6877a5;
}
.full-search-2 .form-control::placeholder {
  color: #6877a5;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  right: 14px;
  top: 1px;
}
.image-bg .form-control,
.bg--dark .form-control {
  color: #495057 !important;
}
select.form-control:not([size]):not([multiple]) {
  height: 56px;
}
select.form-control.fc-lg:not([size]):not([multiple]) {
  height: 62px;
}
.form-control.fc-lg {
  height: 62px;
}
textarea.form-control {
  height: 180px;
}
/*------------- Form --------------*/
.tip-topdata {
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 15px;
  height: 15px;
  display: inline-block;
  background: #394d8c;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  margin-left: 5px;
  font-size: 9px;
}
.tip-topdata:before {
  content: attr(data-tip);
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  bottom: 9999px;
  left: 0;
  background: #394d8c;
  color: #ffffff;
  padding: 7px 15px;
  line-height: 25px;
  border-radius: 3px;
  top: -42px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
  text-shadow: none;
  display: inline-table;
}

.tip-topdata:hover:before {
  opacity: 1;
}
.form-submit .select2-container--default .select2-selection--single {
  background-color: #f9fafc;
  border-radius: 0;
  border: none;
  height: 56px;
  border: 1px solid #ebedf5;
  border-radius: 4px;
  padding-left: 15px;
}
.form-submit
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 55px;
}
.single-widget {
  border: 2px solid #e5e8f3;
  padding: 34px 28px 20px;
  background: #ffffff;
  margin: 0 0 40px;
  border-radius: 12px;
  box-shadow: 0 2px 8px -2px rgb(0 0 0 / 20%);
}
.form-group .redass .red {
  border: 1px solid #cccccc;
  border-radius: 4px !important;
  height: 54px;
}

.submit-page {
  position: relative;
  width: 100%;
  padding: 2em;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(62 28 131 / 10%);
  border-radius: 10px;
}

.newass::before {
  border: 2px solid red !important;
  content: '';
}
.disabled {
  pointer-events: none;
}
.page-link.disabled {
  cursor: no-drop;
}
.inputerror {
  border: 1px solid #e21137 !important;
}
.content-css {
  padding: 16px;
  text-align: center;
}
.show-more-less-clickable {
  color: #0055ff;
  cursor: pointer;
}
