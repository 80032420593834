/*----------------- Style 2 --------------*/
body {
  background: #ffffff;
  color: #4e5c79;
  font-size: 16px;
  font-family: 'Manrope', sans-serif !important;
  margin: 0;
  overflow-x: hidden !important;
  font-weight: 400;
}
.property_block_wrap {
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #e6eaf3;
}
.property_block_wrap.style-2 {
  border-radius: 6px;
  border: 1px solid #e9f1fd;
}
.property_block_wrap.style-2 .block-body {
  padding: 0rem 1.5rem 1rem;
}
.property_block_wrap.style-2 .property_block_wrap_header h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Manrope', sans-serif !important;
  color: #140c40;
}
.property_block_wrap.style-2 .property_block_wrap_header .collapsed h4:after {
  transform: rotate(180deg);
}
.property_block_wrap.style-2 .property_block_wrap_header h4:before {
  display: none;
}
.property_block_wrap.style-2 .property_block_wrap_header {
  padding: 1rem 1.5rem;
  border: none;
}
.property_block_wrap.style-2 .property_block_wrap_header.no-collapse h4:after {
  display: none;
}
ul.deatil_features {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
ul.deatil_features li {
  width: 33.333333%;
  flex: 0 0 33.333333%;
  display: inline-block;
  font-size: 14px;
  color: #505a70;
  margin-bottom: 1.2rem;
  line-height: 1.5;
}
ul.deatil_features li strong {
  color: #2d3954;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}
.nearby-wrap {
  margin-bottom: 2rem;
}
.nearby-wrap:last-child {
  margin-bottom: 0rem;
}
.neary_section_list {
  width: 100%;
  position: relative;
  display: block;
}
.neary_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 0;
}

.neary_section:last-child {
  border-bottom: none;
}
.neary_section_first {
  flex: 1;
}
.neary_section_first .nearby_place_title {
  font-size: 14px;
  margin: 0;
  color: #140c40;
  font-family: 'Manrope', sans-serif !important;
}
.neary_section_last {
  display: flex;
  align-items: center;
}

.neary_section_first .nearby_place_title > small {
  margin-left: 10px;
}
.neary_section_last i {
  font-size: 10px;
  color: #ced1e4;
}
.neary_section_last i.filled {
  color: #ff9800;
}
.reviews-count {
  margin-left: 5px;
}
.nearby_header {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}
.nearby_header_first {
  flex: 1;
}
.nearby_header_first h5 {
  margin: 0;
  font-size: 16px;
}
.nearby_header_last .nearby_powerd {
  display: flex;
  align-items: center;
}
.nearby_header_last .nearby_powerd img {
  max-width: 50px;
  margin-left: 10px;
}
.list-fx-features {
  margin-top: 15px;
  justify-content: flex-start;
}
.listing-card-info-icon {
  width: auto;
  flex: inherit;
  display: inline-flex;
  margin-right: 1.2rem;
  align-items: center;
}
.listing-card-info-icon:last-child {
  margin: 0;
}
.prt-price-fix {
  line-height: 1.4;
  color: #0fca98;
}
.prt-price-fix sub {
  font-size: 15px;
  font-weight: 400;
  color: #929fc5;
}
.featured_slick_gallery {
  position: relative;
}
.btn-view-pic {
  position: absolute;
  bottom: 30px;
  right: 50px;
  background: #ffffff;
  padding: 15px 26px;
  border-radius: 5px;
  font-weight: 600;
  color: #08184b;
}
.btn-view-pic.top {
  position: absolute;
  top: 30px;
  bottom: auto;
  right: 50px;
}
.prt-types.sale {
  background: rgba(247, 68, 0, 0.1);
  color: #f74400;
}
.prt-types {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 12px;
  margin-top: 5px;
  border-radius: 50px;
  background: #efefef;
  display: inline-block;
}
.like_share_wrap {
  background-color: #fff;
  color: #707070;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #e9f1fd !important;
  transition: 0.25s;
  margin-bottom: 20px;
}
ul.like_share_list {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
ul.like_share_list li a {
  width: 100%;
  color: #03a98a !important;
  background: rgba(3, 169, 138, 0.1);
  border: 1px solid #03a98a;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 14px;
}
.btn {
  color: #ffffff;
  padding: 12px 25px;
  cursor: pointer;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  border-radius: 0.1rem;
}
ul.like_share_list li {
  flex: 0 0 50%;
  padding: 0 5px;
  list-style: none;
  width: 50%;
}
ul.like_share_list li:last-child a {
  background: rgba(249, 93, 2, 0.1);
  color: #f95d02 !important;
  border-color: #f95d02;
}
ul.deatil_features_full li {
  width: 100%;
  flex: 0 0 100%;
  display: inline-block;
  font-size: 14px;
  color: #505a70;
  margin-bottom: 1.2rem;
  line-height: 1.5;
}
.pricing-5 ul li:nth-child(odd) {
  background: #f4f4f4;
}
@media (min-width: 990px) {
  .pricing-5 ul li {
    height: 4.875em;
  }
}

.pricing-5 ul li {
  line-height: 1.625em;
  padding: 1.625em 0;
  list-style: none;
}
ul:last-child {
  margin: 0;
}
.pricing-5 {
  overflow: hidden;
}
.block-body {
  line-height: 1.7;
  display: inline-block;
  width: 100%;
}
.avl-features {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.avl-features li {
  display: block;
  list-style: none;
  position: relative;
  margin: 10px 0 10px;
  padding-left: 30px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.property_block_wrap.style-2 .property_block_wrap_header h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Manrope', sans-serif !important;
  color: #140c40;
}
.author-review article .comment-details {
  overflow: hidden;
  padding-left: 30px;
}
.author-review article .comment-details .comment-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.author-review article .comment-details .comment-meta .comment-left-meta {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
}
.author-review
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .author-name {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 0px;
}
.author-review
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .comment-date {
  color: #00ba74;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 7px 0 0;
}
.author-review article .comment-details .comment-text {
  margin: 19px 0 0;
}
.author-review article .comment-details .comment-text p {
  margin: 0;
}
li.article_comments_wrap:last-child {
  border: none;
  margin: 0;
}
.author-review article {
  overflow: hidden;
  padding-bottom: 25px;
}
li.article_comments_wrap {
  border-bottom: 1px dashed #e3e7ea;
  margin-bottom: 20px;
}
.blue-skin .theme-cl {
  color: #005b69;
}
.reviews-checked {
  text-align: center;
  display: table;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
}
h4 {
  line-height: 26px;
  font-size: 21px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.sides_list_property {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.8rem;
  border: 1px solid #e7eaec;
  border-radius: 0.4rem;
  background: #ffffff;
  margin-bottom: 15px;
}
.sides_list_property_thumb {
  width: 125px;
  height: 75px;
  border-radius: 4px;
  overflow: hidden;
}
.sides_list_property_thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.br-16 img {
  border-radius: 16px;
  margin-bottom: 16px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.sides_list_property_detail {
  padding-left: 0.7rem;
  width: 100%;
}
.sides_list_property_detail h4 {
  font-size: 16px;
  line-height: 1;
  margin: 0px 0 2px;
}
.sides_list_property_detail span {
  font-size: 13px;
}
.lists_property_price {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.lists_property_price .lists_property_types {
  flex: 1;
}
.property_types_vlix.sale {
  color: #03a98a;
  background: rgba(3, 169, 138, 0.1);
}

.property_types_vlix {
  display: inline-block;
  padding: 4px 15px;
  color: #f95d02;
  background: rgba(249, 93, 2, 0.1);
  border-radius: 50px;
  font-size: 13px;
}
.author-review article .article_comments_thumb {
  float: left;
  width: 80px;
}
.author-review article .article_comments_thumb img {
  max-width: 80px;
  border-radius: 50%;
}

.pricing-5 div[class*='col-'] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 0;
}
.pricing-5 ul {
  margin: 0;
  padding: 0;
}
.chemical_link {
  color: blue !important;
}
tbody {
  text-align: center;
}
.test {
  position: absolute;
  color: #00ba74;
  width: 20px;
  height: 20px;
  background: rgba(0, 186, 116, 0.1);
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  left: 0;
  top: 5px;
  font-size: 10px;
}
.shareIcons {
  margin-right: 7px;
  line-height: 1;
}
.besal-underline:hover {
  color: #182e89 !important;
}
.like_share_wrap .besal-underline {
  text-decoration: underline !important;
  color: #2445cd !important;
}
.link-dot {
  color: #140c40 !important;
  font-size: 0.8rem;
  margin-right: 8px;
}
.link-icon-cus {
  margin-left: 4px;
}
.cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 5 equal-width columns */
  grid-auto-rows: 30px; /* Height of each row */
  gap: 10px;
}
