.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: flex;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}
.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}
.checkbox-custom + .checkbox-custom-label:before {
  content: '';
  background: #fff;
  border: 2px solid #dae3ec;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}
.checkbox-custom:checked + .checkbox-custom-label:before {
  content: '✔️';
  background: #140c40 !important;
  border-radius: 2px;
  box-shadow: none;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '✔️';
  width: 20px;
  height: 20px;
  background: #140c40 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #ffffff;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
  outline: none; /* focus style */
}
.filter_wraps .checkbox-custom + .checkbox-custom-label:before {
  right: 0;
  margin-right: 0px;
  position: absolute;
}
.filter_wraps .checkbox-custom:checked + .checkbox-custom-label:before {
  box-shadow: none;
}
.filter_wraps .checkbox-custom,
.filter_wraps .checkbox-custom-label,
.filter_wraps .radio-custom,
.radio-custom-label {
  width: 100%;
  margin: 0;
}
