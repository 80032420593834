.icon-mi-left {
  display: flex;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 1.2em 2em;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}
.icon-mi-left i {
  font-size: 55px;
  color: #333;
  text-align: left;
  position: relative;
  top: 5px;
  left: 0;
  line-height: 55px;
}
.icon-mi-left .icon-mi-left-content {
  padding: 10px 0 0 15px;
}
.icon-mi-left h4 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.icon-mi-left p {
  line-height: 1.7;
}
.single-team {
  padding: 15px 10px;
}
.team-grid {
  background: #ffffff;
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  padding: 3em 0;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -moz-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}
.single-team {
  padding: 15px 10px;
}
.teamgrid-user {
  max-width: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.teamgrid-content {
  padding: 15px 0;
  text-align: center;
}
.teamgrid-content h4 {
  margin: 0;
}
.teamgrid-social ul {
  text-align: justify;
  margin: 0;
  padding: 1rem;
}
.teamgrid-social ul li {
  display: inline-block;
  list-style: none;
  padding: 0 10px;
}
.f-cl {
  color: #1943d0;
}
.t-cl {
  color: #12b0e8;
}
.i-cl {
  color: #d62ba4;
}
.l-cl {
  color: #0c9a9e;
}
.g-cl {
  color: #f53500;
}
.p-cl {
  color: #d21818;
}
.icon-mi-left {
  display: flex;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 1.2em 2em;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}
.icon-mi-left i {
  font-size: 55px;
  color: #333;
  text-align: left;
  position: relative;
  top: 5px;
  left: 0;
  line-height: 55px;
}
.icon-mi-left .icon-mi-left-content {
  padding: 10px 0 0 15px;
}
.icon-mi-left h4 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.icon-mi-left p {
  line-height: 1.7;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
