.image-bg {
  background-size: cover !important;
  background-position: center !important;
}
.image-cover {
  background-size: cover !important;
  background-position: center !important;
}
.image-bottom {
  background-size: auto !important;
  background-position: bottom !important;
}
.hero-banner {
  padding: 5em 0 5em;
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}
.hero-search-wrap {
  position: relative;
  background: #ffffff;
  max-width: 540px;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 40px 40px;
  box-shadow: 0 20px 30px rgba(0, 22, 84, 0.15);
}
.hero-search-wrap.hs-2 {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 1rem 1rem 0rem;
}
.hero-search-wrap.full-width {
  width: 100%;
  max-width: 100%;
}
.hero-search h1 {
  text-transform: capitalize;
  color: #2d3954 !important;
  font-size: 40px;
}
.btn.search-btn {
  background: #005b69;
  font-family: 'Manrope', sans-serif !important;
  padding: 10px;
  border-radius: 5px;
  box-shadow: none !important;
  color: #ffffff !important;
  width: 100%;
  font-size: 1.2rem;
}
.btn.search-btn:hover,
.btn.search-btn:focus {
  color: #ffffff !important;
}
.hero-banner.dark-text h1,
.hero-banner.dark-text p,
.hero-banner.dark-text h1,
.hero-banner.dark-text p {
  color: #4d5968;
}
.hero-header {
  background: #232323;
  background-position: center;
  background-size: cover;
  padding: 4em 0;
  position: relative;
}

.hero-header {
  margin: 0;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.hero-banner > * {
  position: relative;
  z-index: 1;
}
.hero-banner h1 {
  font-weight: 800;
  line-height: 1.3;
}
.hero-banner p {
  font-size: 18px;
}

.form-control {
  font-family: 'Manrope', sans-serif !important;
  border-radius: 0px !important;
}
.signup-frm {
  max-width: 510px;
  margin-top: 15px;
  display: block;
  position: relative;
}
.signup-frm .form-control {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  padding: 22px 20px 18px;
  border-radius: 2px;
  height: 60px;
}
.signup-frm .btn {
  bottom: 4px;
  position: absolute;
  right: 4px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 18px 25px 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
  width: auto;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  height: 52px;
}
.input-with-icon {
  position: relative;
  width: 100%;
}
.input-with-icon .form-control,
.input-with-shadow .form-control,
.input-with-shadow .select2-container,
.input-with-icon .select2-container {
  border: none;
  padding-left: 45px;
  height: 60px;
  background: #ffffff;
  overflow: hidden;
}
.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
  color: #a2a9bf;
  font-style: normal;
  cursor: normal;
  pointer-events: none;
}
.input-with-icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  cursor: normal;
}
.hero-search-content.side-form .form-control,
.hero-search-content.side-form
  .select2-container--default
  .select2-selection--single {
  background: #fff !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}
.hero-search-content.side-form
  .select2-container--default
  .select2-selection--single {
  background: #edf1fb !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding-left: 15px !important;
}

.hero-search-content.side-form label,
label {
  font-size: 14px;
  font-weight: 600;
  color: #455892;
  font-family: 'Manrope', sans-serif !important;
}
.hero-search-content.side-form .form-control::-webkit-input-placeholder {
  color: #6877a5;
}
.hero-search-content.side-form .form-control:-ms-input-placeholder {
  color: #6877a5;
}
.hero-search-content.side-form .form-control::placeholder {
  color: #6877a5;
}

.location-property-wrap {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  margin-bottom: 30px;
  transition: all ease 0.4s;
  border: 1px solid #e2e6f1;
}
.location-property-thumb {
  position: relative;
  overflow: hidden;
}
.location-property-thumb img {
  transition: all ease 0.4s;
}
.location-property-content {
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.lp-content-flex {
  flex: 1;
}
.lp-content-flex .lp-content-title {
  font-size: 17px;
  margin-bottom: 0px;
}
.lp-content-right .lp-property-view {
  width: 40px;
  height: 40px;
  background: #0fca98;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.4s;
  border-radius: 50%;
}
.location-property-wrap:hover .location-property-thumb img,
.location-property-wrap:focus .location-property-thumb img {
  transform: scale(1.5);
}
.filter-sidebar {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  transition: 0.5s;
  padding-top: 50px;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}
.filter-sidebar .show-hide-sidebar {
  padding: 10px 20px;
  overflow-y: scroll;
  position: relative;
  width: calc(100% + 17px);
  min-height: 300px;
  display: block;
  height: 100%;
}
.filter-sidebar .ur-detail-wrap {
  padding: 0 1.4em 2em 1.4em;
}
.filter-sidebar .closebtn {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 36px;
  margin-left: 50px;
  width: 30px;
  height: 30px;
  background: #1ac790;
  text-align: center;
  font-size: 11px;
  padding: 0;
  line-height: 32px;
  color: #ffffff;
  border-radius: 50%;
}

.simple-sidebar {
  border-radius: 0.4rem; 
  border: 1px solid #e6eaf1;
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
}
.simple-sidebar .input-with-icon .form-control,
.simple-sidebar .input-with-shadow .form-control,
.simple-sidebar .input-with-shadow .select2-container,
.simple-sidebar .input-with-icon .select2-container {
  box-shadow: none;
  border: 1px solid #e7ebf1;
}
.search-sidebar_header {
  display: none;
  position: relative;
  width: 100%;
  height: 3rem;
  padding: 0rem 0rem 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #dfe4ec;
  margin-bottom: 1rem;
}
.search-sidebar_header .ssh_heading {
  margin: 0;
  flex: 1;
  font-size: 16px;
}
.simple-sidebar button.w3-bar-item.w3-button.w3-large {
  background: #ffffff;
  border: none !important;
  cursor: pointer;
  outline: none !important;
  color: #465169;
}
@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

.search-styles-2 {
  border-radius: 0.4rem;
  border: 1px solid #e6eaf1;
  background: #ffffff;
  margin-bottom: 30px;
  overflow: hidden;
}
.search-inner {
  padding: 2rem 0 1rem;
}
.single_search_boxed {
  padding: 0;
  border-top: 1px solid #eef0f5;
  display: inline-block;
  width: 100%;
}
.search-styles-2 label {
  font-size: 13px;
  font-weight: 500;
  color: #455892;
}
.single_search_boxed:first-child {
  border-top: none;
}
.widget-boxed-header {
  padding: 0;
  width: 100%;
  position: relative;
  display: block;
}
.widget-boxed-header h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}
.widget-boxed-header h4 a {
  height: 100%;
  display: block;
  color: #2d3954 !important;
  position: relative;
  padding: 1rem 0rem;
}
.widget-boxed-header h4 a:before {
  content: '\e622';
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 52%;
  transform: translate(-10%, -52%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  font-family: themify;
  font-size: 10px;
  color: #445977;
}
.widget-boxed-header h4 a.collapsed:before {
  content: '\e61a';
  background: #f5f7fb;
  color: #140c40;
}
.hm_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1.5rem 20px 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #ecf0f7;
}
.hm_nav_title {
  font-size: 17px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Campton W00 Bold', 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  position: absolute;
  letter-spacing: 0.4px;
  left: 0;
  right: 0;
  margin: 0;
}
span.mod-close {
  font-size: 15px;
  color: #1a1e2b !important;
  cursor: pointer;
  z-index: 1;
  box-shadow: none !important;
}
span.hm_nav_clear {
  font-weight: 600;
  color: #140c40;
  font-size: 13px;
}
.filter-search-box .form-group .form-control {
  margin: 0 !important;
  box-shadow: none !important;
  background: rgba(174, 189, 219, 0.12);
  border: 1px solid transparent;
  color: #707e9c;
  border-radius: 4px;
}
.filter-search-box,
.form-group.filter_button {
  padding: 0em;
}
.widget-boxed-header .selected {
  display: block;
  line-height: 1.2;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  color: #72809d;
  font-family: 'Manrope', sans-serif !important;
}
.filter_wraps .radio-custom + .radio-custom-label:before {
  margin-right: 0px;
}
.filter_wraps [type='radio']:checked + label,
.filter_wraps [type='radio']:not(:checked) + label {
  padding-left: 0;
  width: 100%;
}
.filter_wraps [type='radio']:checked + label:before,
.filter_wraps [type='radio']:not(:checked) + label:before {
  right: 0;
  left: auto;
}
.filter_wraps [type='radio']:checked + label:after,
.filter_wraps [type='radio']:not(:checked) + label:after {
  right: 3px;
  left: auto;
}
.filter-list li {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(20, 12, 64, 0.12);
}
.filter-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.filter_wraps .checkbox-custom + .checkbox-custom-label:before {
  right: 0;
  margin-right: 0px;
  position: absolute;
}
.filter_wraps .checkbox-custom:checked + .checkbox-custom-label:before {
  box-shadow: none;
}
.filter_wraps .checkbox-custom,
.filter_wraps .checkbox-custom-label,
.filter_wraps .radio-custom,
.radio-custom-label {
  width: 100%;
  margin: 0;
}

.advance-search .form-group {
  margin-bottom: 0rem;
}
.advance-search .search-big-form .form-group i {
  color: #fd5332;
}
.adv-search-filter {
  float: right;
  margin-bottom: 1rem;
  display: inline-block;
}
.adv-filter-box {
  display: inline-block;
  margin-left: 15px;
}
.btn-flit {
  width: 42px;
  border-radius: 2px;
  border: 1px solid #d1dade;
  color: #646e7b;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-flit.active {
  color: #fd5332;
}
.btn.btn-order-by-filt {
  background: transparent;
  border: 1px solid #d1dade;
  color: #646e7b;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.btn.btn-order-by-filt i {
  margin-left: 5px;
}
.back-brow {
  display: inline-block;
  margin-bottom: 1rem;
}
.back-btn {
  font-weight: 600;
  opacity: 0.9;
}
.back-btn i {
  margin-right: 5px;
}
.back-btn:hover,
.back-btn:focus {
  opacity: 1;
}
.search-icon {
  margin: 0 16px 0 0;
}
.select__input {
  height: 40px;
}
select__menu-list::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}
select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
select__menu-list::-webkit-scrollbar-thumb {
  background: #888;
}
select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}
.dataResult {
  position: absolute;
  z-index: 99;
  margin-top: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 4px;
  width: 85%;
 
}

.dataResultShow{
  height: auto;
  max-height:338px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  padding: 10px 10px 10px;
}

.dataItem:hover {
  background-color: #f5f5f5;
}

.dataItem p {
  margin-left: 10px;
}
/* I'm here */
.input-with-icon .preloader {
  position: absolute;
  right: 0;
  margin-left: auto;
}
.full-search-2.italian-search .form-group {
  margin-bottom: 0px;
  height: 100%;
}
.italian-search.hero-search-radius .btn.search-btn {
  border-radius: 0.2rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-search-2.hero-search-radius {
  position: relative;
}
.full-search-2.hero-search-radius {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  padding: 0.8rem 1.5rem;
  border-radius: 0.3rem;
  border: 1px solid #D6D9DA;
}
.search-bottom-text {
  font-size: 14px !important;
  padding-top: 16px !important;
}
.search-bottom-text .btext {
  padding-right: 32px !important;
}
.phy-row {
  padding-left: 0px !important;
}
.custom-form-group {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  position: relative;
}
.cst-input {
  display: flex;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #D6D9DA;
}
.my-input-with-icon {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin: 0;
  border-radius: 8px 0px 0px 8px !important;
}
.search-bbtn {
  margin: 5px;
  padding: 1rem 1.4rem;
  border-radius: 3px;
  cursor: pointer;
  text-transform: capitalize;
  color: #ffffff;
  width: 136px !important;
}
.input-with-icon .form-control,
.input-with-shadow .form-control,
.input-with-shadow .select2-container,
.input-with-icon .select2-container {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.hero-footer {
  display: flex;
} 
.link {
  cursor: pointer;
  text-align: center;
  background: rgba(255, 255, 255, 0.5) !important;
  color: #005b69;
  padding: 32px !important;
  margin: 16px;
  flex: 1 0;
  border-radius: 24px;
  border: 1px solid #D6D9DA;
}
.phy-hero-icons {
  font-size: 14px !important;
  color: #4e5c79;
}
.phy-hero-icon {
  font-size: 36px !important;
}
.phy-hero-icon svg {
  fill: #005b69;
}
