.list-layout .property-listing.property-1 {
  display: flex;
  margin: 0 0 30px 0;
}
.property-listing {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 30px;
  border: none;
  box-shadow: 0 0 20px 0 rgb(62 28 131 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(62 28 131 / 10%);
}
.property-listing {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e5e9ec;
}
.list-layout .property-listing.property-1 .listing-img-wrapper {
  height: 0;
  min-height: 210px;
  flex: 1 2;
  overflow: hidden;
  border-radius: 3px 0 0 3px;
}

.property-listing.property-1 .listing-img-wrapper {
  overflow: hidden;
  position: relative;
}
.list-layout .property-listing.property-1 .listing-img-wrapper a {
  min-height: 210px;
}
.property-listing.property-1 .listing-img-wrapper > a {
  position: relative;
  display: block;
  width: 100%;
  transition: all ease 0.4s;
}
.list-layout .property-listing.property-1 .listing-img-wrapper img {
  height: 210px;
  object-fit: cover;
}

.property-listing.property-1 .listing-img-wrapper img {
  max-height: 240px;
  width: 100%;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
img,
svg {
  vertical-align: middle;
}
.list-layout .property-listing.property-1 .listing-content {
  flex: 3;
  position: relative;
}
.list-layout .property-listing.property-1 .listing-detail-wrapper {
  padding: 1rem 1rem 0.5rem;
}
.property-listing.property-1 .listing-detail-wrapper {
  padding: 1.5rem 1rem;
  width: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.property-listing.property-1 .listing-short-detail {
  flex: 1;
}
.list-layout .property-listing.property-1 .listing-name {
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 1.2;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2d3954;
  font-weight: 700;
  text-transform: capitalize;
  font-family: 'Manrope', sans-serif;
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

.fr-can-rating {
  display: flex;
  align-items: center;
}
.fr-can-rating i.filled {
  color: #ff9800;
}
.fr-can-rating i {
  font-size: 10px;
  margin-right: 3px;
  color: #c4c8d0;
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.prt-types.sale {
  margin-top: 8px;
  background: rgba(247, 68, 0, 0.1);
  color: #f74400;
}
.prt-types {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  margin-top: 5px;
  border-radius: 50px;
  background: #efefef;
  display: inline-block;
}
.listing-card-info-price {
  font-weight: 700;
  position: relative;
  font-size: 14px;
  color: #27ae60;
  background: #ebf6f0;
  line-height: 1.4;
  border-radius: 12px;
  padding: 6px 12px;
}
.list-layout .price-features-wrapper {
  padding: 0.1rem 1.5rem 0.6rem;
}
.price-features-wrapper {
  padding: 0.1rem 1.5rem 0.6rem;
  display: flex;
  flex-wrap: wrap;
}
.list-fx-features {
  margin-top: 15px;
  display: flex;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px !important;
}
.listing-card-info-icon {
  margin-right: 1.2rem;
  align-items: center;
}

.listing-card-info-icon {
  display: inline-flex;
  padding-left: 0px;
  position: relative;
  vertical-align: top;
  width: auto;
  flex: inherit;
  margin-bottom: 0rem;
  font-size: 14px;
  color: #616e96;
  font-weight: 600;
}
.inc-fleat-icon {
  width: 25px;
  height: 25px;
  background: #edf1f9;
  border-radius: 50%;
  margin-right: 7px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #e0e4ef; */
}
.list-layout .property-listing.property-1 .listing-footer-wrapper {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e5e9ec;
  width: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.property-listing.property-1 .listing-footer-wrapper {
  padding: 0.9rem 1rem;
  border-top: 1px solid #e5e9ec;
  width: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.listing-locate {
  flex: 1;
  font-size: 12px;
  line-height: 14px;
}
.property-listing.property-1 .listing-location {
  font-size: 15px;
  margin-bottom: 2px;
}

.list-layout .listing-detail-btn .more-btn {
  border-radius: 50px !important;
  padding: 6px 17px !important;
}
.reviews_text {
  margin-left: 8px;
  font-size: 13px;
  font-weight: 600;
}
.listing-detail-btn .prt-view {
  padding: 6px 16px;
  color: #ffffff !important;
  background: #005b69;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}
.w-8 {
  width: 2rem;
}

.h-8 {
  height: 2rem;
}
.npl {
  padding-right: 0.5rem;
}
.svg {
  transform: rotate(180deg);
}
.page-title {
  width: 100%;
  height: 200px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background: #005b69;
}
.page-title .ipt-title {
  color: #ffffff;
}

.ipt-title {
  margin-bottom: 5px;
}
.ipn-subtitle {
  font-size: 17px;
  font-family: 'Manrope';
}

.ipn-subtitle {
  color: #b3ced2;
}
.pagination {
  display: table;
  padding-left: 0;
  border-radius: 4px;
  margin: 20px auto 0 !important;
}

.pagination {
  list-style: none;
}
.pagination > li {
  display: inline;
}
.pagination li:first-child a {
  background: #005b69;
  border-color: #005b69;
}

.pagination li:first-child a {
  background: #005b69;
  border: 1px solid #005b69;
  border-radius: 2px;
  color: #fff;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 0;
  margin: 5px;
  color: #5a6f7c;
  text-decoration: none;
  background-color: #fff;
  border-radius: 2px;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  border: 1px solid #eaeff5;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #005b69;
  border-color: #005b69;
}
