/*--------------------------------------------------
	Responsiveness Start
---------------------------------------------------*/
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  } 
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}

@media all and (max-width: 1199px) and (min-width: 991px) {
  .list-layout .property-listing.property-1 .listing-name {
    font-size: 13px;
  }
  .listing-card-info-price {
    font-size: 18px;
  }
}

@media (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}

@media (min-width: 768px) {
  form.search-big-form.search-shadow .form-group,
  form.search-form.search-shadow .form-group {
    margin-bottom: 0;
  }
  .no-ul-list.third-row li {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  .advance-search.advn-fixed {
    position: fixed;
    top: 89px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
}

@media (min-width: 992px) {
  .modal-dialog.modal-lg {
    max-width: 700px;
    margin: 100px auto 30px;
  }
  .side_stiky {
    position: sticky;
    top: 100px;
  }
  .header.header-fixed {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background: #ffffff;
    position: fixed;
    z-index: 999; 
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  .filter_search_opt {
    display: none;
  }
  .header.header-transparent {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    z-index: 4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .change-logo .nav-brand.fixed-logo {
    display: none;
  }
  .change-logo.header-fixed .nav-brand.fixed-logo {
    display: block;
  }
  .change-logo.header-fixed .nav-brand.static-logo {
    display: none;
  }
  .header.header-transparent.header-fixed {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  .header.header-transparent .nav-brand,
  .header.header-transparent .nav-brand:focus,
  .header.header-transparent .nav-brand:hover,
  .header.header-transparent .nav-menu > li > a {
    color: #ffffff;
  }
  .header.header-transparent.dark .nav-brand,
  .header.header-transparent.dark .nav-brand:focus,
  .header.header-transparent.dark .nav-brand:hover,
  .header.header-transparent.dark .nav-menu > li > a {
    color: #39466d;
  }
  .header.header-transparent .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-transparent .nav-submenu .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
  }

  .header.header-transparent.dark .submenu-indicator-chevron {
    border-color: transparent #39466d #39466d transparent;
  }
  .header .nav-menu > .active > a .submenu-indicator-chevron,
  .header .nav-menu > .focus > a .submenu-indicator-chevron,
  .header .nav-menu > li:hover > a .submenu-indicator-chevron {
    border-color: transparent #0fca98 #0fca98 transparent;
  }
  .header.header-transparent.header-fixed .nav-brand,
  .header.header-transparent.header-fixed .nav-brand:focus,
  .header.header-transparent.header-fixed .nav-brand:hover,
  .header.header-transparent.header-fixed .nav-menu > li > a {
    color: #647392;
  }
  .header.header-transparent.header-fixed .submenu-indicator-chevron {
    border-color: transparent #647392 #647392 transparent;
  }
  .nav-menu.nav-menu-social > li.add-listing a {
    top: -16px;
  }
  .hero-banner {
    min-height: 580px;
  }
  .hero-search h1 {
    font-size: 40px;
  }
  h1,
  .h1 {
    font-size: 4.142em;
    line-height: 1.31818182em;
  }
  .breadcrumbs {
    margin-top: 92px;
  }
  .ct-header h1.big {
    font-size: 70px;
    line-height: 1;
  }
  .ct-u-display-tablex {
    min-height: 500px;
    height: 100vh;
  }
  .main-banner.image-banner,
  .hero-header,
  .hero-banner {
    min-height: 660px;
  }
  .hero-header.min-banner {
    height: 80vh;
    min-height: 400px;
  }

  .hero-banner h2,
  .main-banner h1 {
    font-size: 60px;
    line-height: 1.1;
  }

  .hero-content h1 {
    font-size: 60px;
  }
  .content h1 {
    font-size: 70px;
  }
  .hero-content p {
    font-size: 22px;
  }

  .jumbo-banner.software .hero-content {
    margin-top: 0em;
  }
  .jumbo-banner.software .hero-content h1 {
    font-size: 60px;
  }
  .sec-heading h2 {
    font-size: 29px;
    line-height: 1.2;
  }
  .page-title-wrap h1 {
    font-size: 55px;
    line-height: 1.4;
  }

  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }
  .cta-sec h1,
  .cta-sec h2 {
    font-size: 38px;
    margin-bottom: 12px;
  }
}
@media (min-width: 993px) {
  .head-shadow {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  }
  .header.head-border {
    border-bottom: 1px solid #e9ecef;
  }
}

@media (min-width: 1200px) {
  .hero-content h1 {
    font-size: 80px;
  }
  .core-nav .megamenu.open > .megamenu-content {
    width: 700px !important;
  }
}
@media (max-width: 1199px) {
  .property-listing.property-1 .listing-location {
    font-size: 13px;
    margin-bottom: 2px;
  }
  .job-new-list .btn.btn-outline-info.float-right {
    float: none !important;
    margin: 0;
    display: table;
  }
  .price-features-wrapper {
    padding: 0.1rem 1rem 0.6rem;
  }
  .listing-card-info-icon {
    margin-right: 0.7rem;
    font-size: 12px;
  }
  .author-box {
    width: 55px;
    height: 55px;
  }
  .property-listing.property-2 .listing-detail-wrapper {
    padding: 1rem 1rem;
  }
  .foot-location {
    font-size: 13px;
    display: flex;
  }
  .listing-short-detail-flex.fl-2 {
    flex: 2.5;
  }
  .verticle-job-detail .jb-title {
    font-size: 18px;
  }
  .verticle-job-thumb {
    max-width: 60px;
    width: 60px;
  }
  .verticle-job-detail {
    margin-left: 65px;
  }
  ul li.submit-attri {
    display: none;
  }

  .dw-proprty-info li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1023px) {
  .half-map .fs-inner-container {
    width: 100%;
  }

  .fs-left-map-box .hm-map-container.fw-map {
    width: 100%;
    height: 585px;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;
  }
  .half-map .fs-left-map-box {
    float: none;
  }
  .fr-grid-info ul li {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .header.nav-left-side .core-nav .wrap-core-nav-list .core-nav-list {
    float: none;
  }
  .nav-menu > li .nav-dropdown {
    background: transparent;
    padding: 0;
    box-shadow: none;
  }
  .nav-menu > li > .nav-dropdown:before {
    display: none;
  }
  .navigation-portrait .nav-menu.nav-menu-social {
    padding-top: 1rem;
  }
  .header-dark-transparent nav .menu li a {
    color: #606c8e;
  }
  .header-dark-transparent button.toggle-bar.core-nav-toggle {
    color: #ffffff;
  }
  .header-dark-transparent.header-fixed button.toggle-bar.core-nav-toggle {
    color: #728496;
  }
}
@media (max-width: 991px) {
  .main-banner.image-banner,
  .hero-header,
  .hero-banner,
  .large-banner {
    min-height: 580px;
  }
  .pricing-body ul li {
    padding: 9px 10px;
    font-size: 15px;
  }
  .sm-sidebar {
    height: 100%;
    max-width: 300px;
    min-width: 310px;
    background-color: #fff;
    position: fixed !important;
    top: 0;
    z-index: +2000;
    overflow: auto;
    -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;
    position: relative;
    animation: animateleft 0.4s;
    left: 0;
    display: none;
    border-radius: 0;
  }
  .dashboard .search-sidebar_header {
    padding: 0 1rem;
  }
  .change-logo .nav-brand.static-logo {
    display: none;
  }
  .nav-menu.nav-menu-social > li.add-listing a {
    top: 0px;
    padding: 15px 20px;
  }
  .search-sidebar_header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  ul.attributes {
    float: right;
  }
  button.toggle-bar.core-nav-toggle {
    color: #8594b3;
  }
  .nav-menu.nav-menu-social > li.add-listing {
    top: 0;
  }
  .core-nav .dropdown > .dropdown-menu {
    width: 100%;
  }
  .show-mb {
    display: initial !important;
  }

  h1.italian-header-capt {
    font-size: 25px;
  }
  .pricing-body ul li {
    padding: 9px 0;
    font-size: 15px;
  }
  list-layout .property-listing.property-1 .listing-img-wrapper {
    flex: initial;
  }
  .list-layout .property-listing.property-1 .listing-content {
    flex: initial;
    position: relative;
  }
  .list-layout .property-listing.property-1 {
    display: block;
    margin: 0 0 30px 0;
  }
  .list-layout .property-listing.property-1 .listing-footer-wrapper {
    padding: 0.9rem 1rem;
    border-top: 1px solid #e5e9ec;
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    justify-content: initial;
    left: 0;
  }

  .agency-list {
    display: block;
  }

  .avl-features li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .slide-property-detail {
    position: relative;
    display: block;
  }
  .slide-property-first {
    flex: initial;
    margin-bottom: 1.5rem;
  }
  .single-advance-property .container-fluid.p-0 {
    padding: 0 15px !important;
  }
  .drops-dashboard {
    display: none;
  }
}

@media (max-width: 767px) {
  .call-to-act {
    display: block;
    text-align: center;
  }
  .flt_item_content.align_center .switchbtn-wrap {
    width: 46%;
    margin-right: 10px;
  }
  .hero-search h1 {
    font-size: 20px;
  }
  ._mp_filter.center.mb-3 {
    display: block;
  }
  .selected_item_wrap {
    display: flex;
    flex-flow: wrap;
  }
  .slt_single_item .remove_pills {
    font-size: 11px;
    font-weight: 600;
    color: #144273;
    padding: 10px 7px;
  }
  .slt_single_item .pills_clears {
    font-size: 13px;
  }
  .sty_1523 ._mp_filter_first {
    margin-bottom: 1rem;
  }
  a.map_filter.min {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .property-listing.list_view.style_new {
    display: flex;
    flex-wrap: wrap;
  }
  .property-listing.list_view .list-img-slide {
    min-height: auto;
    max-height: initial;
  }
  .property-listing.list_view.style_new .slick-slide img,
  .property-listing.list_view.style_new .list-img-slide img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  .property-listing.list_view .list_view_flex {
    padding-left: 0;
    padding-top: 12px;
  }
  .property-listing.list_view.style_new .slick-dotted.slick-slider {
    margin-bottom: 00px;
  }
  .property-listing.list_view.style_new .footer-flex .prt-view {
    padding: 4px 12px;
    font-size: 13px;
  }
  ul.shorting_grid {
    display: none;
  }
  .elco_bor {
    border: none;
  }
  .shorting_pagination_laft h5 {
    font-size: 10px;
  }
  .shorting_pagination_right ul li {
    padding: 0 1px;
  }
  .shorting_pagination_right ul li a {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .shorting-right {
    width: auto;
    padding: 1px 0px 5px;
    justify-content: flex-start;
  }
  .shorting_pagination {
    margin-top: 10px;
  }
  .icon-features-wrap:before {
    display: none;
  }
  .middle-icon-features-content h4 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .sec-heading h2 {
    font-size: 23px;
  }
  .item-shorting-box {
    display: block;
  }
  .item-shorting {
    margin-bottom: 1rem;
  }
  .call-to-act-head {
    margin-bottom: 1rem;
  }
  .footer-widget {
    padding: 10px !important;
    padding-bottom: 0;
  }
  .footer-bottom {
    margin-top: 70px;
  }
  .dropdown-submenu > .dropdown-menu.show {
    display: block;
  }
  .full-search-2.hero-search-radius #advance-search {
    top: auto;
  }
  .property-search-type label {
    padding: 8px 16px;
    margin: 0 7px 0 0;
    font-size: 14px;
  }
  .property-search-type label:nth-child(3) {
    margin-right: 0;
  }
  .full-search-2.hero-search-radius #advance-search {
    padding: 2rem 0.5rem;
  }
  .full-search-2.hero-search-radius .checkbox-custom,
  .full-search-2.hero-search-radius .checkbox-custom-label,
  .full-search-2.hero-search-radius .radio-custom,
  .radio-custom-label {
    font-size: 14px;
  }
  .p-0.elio {
    padding: 0 6px !important;
  }
  .full-search-2.eclip-search.italian-search .b-r {
    border-right: none !important;
  }
  .full-search-2.eclip-search.italian-search .b-l {
    border-left: none !important;
  }
  .full-search-2.italian-search .form-group.borders {
    margin-bottom: 10px;
    height: auto;
    border: 1px solid #dce3ec;
    border-radius: 6px;
    overflow: hidden;
  }
  ul.deatil_features li {
    width: 50%;
    flex: 0 0 50%;
  }
  .property_block_wrap.style-3 {
    display: block;
  }
  .pbw-flex-1 {
    margin-bottom: 1rem;
  }
  .pbw-flex {
    padding-left: 0;
  }
  .rating-overview {
    display: block;
  }
  .rating-overview-box {
    width: 100%;
    border-right: none;
    margin-bottom: 1rem;
  }
  .rating-bars-item {
    width: calc(100% - 30px);
  }
  .seo-contact .p-0 {
    padding: 0 15px !important;
  }
  .seo-contact button.btn {
    width: 100%;
  }
  .nav-tabs .nav-item a {
    padding: 0.5em 1em;
  }

  .boxed.boxed--lg {
    padding: 2.77em 1em;
  }
  .icon--lg {
    font-size: 3.57142857em;
  }
  .error-page h2 {
    font-size: 100px;
  }
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
  .listing-detail-item {
    display: inline-block;
    margin-left: 4px;
  }
  .tr-list-thumb {
    max-width: 75px;
    height: 75px;
    margin-right: 10px;
  }
  .tr-list-wrap {
    position: relative;
    bottom: 50px;
    display: block;
  }
  a.btn.btn-list {
    padding: 12px 10px;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .advance-search .form-group {
    margin: 0.6rem 0;
    border: 1px solid #e0ecf5;
  }
  .advance-search .search-big-form {
    padding: 10px !important;
  }
  .job-new-list .vc-thumb,
  .job-new-list .vc-content {
    float: none;
    margin: 0.5rem 0;
    display: table;
  }
  .mpl-0 {
    padding-left: 0 !important;
  }
  .verticle-candidate-list .vc-content {
    margin-top: 1rem;
  }

  .top-header .cn-info {
    display: none;
  }

  .full-search-2.hero-search-radius {
    border-radius: 10px;
  }
  .full-search-2.hero-search-radius .form-control {
    border: none !important;
  }

  .dashboard-navbar {
    margin-bottom: 2rem;
  }
  table.property-table-wrap .property-container img {
    display: none;
  }
  table.property-table-wrap td {
    padding: 35px 2px;
  }
  table.property-table-wrap .property-container .title h4 {
    font-size: 14px;
  }
  table.property-table-wrap td.action a {
    margin-left: 10px;
  }
  th.expire-date {
    display: none;
  }
  .page-sidebar {
    margin-top: 2rem;
  }
  li.login-attri.theme-log a {
    padding: 0 22px !important;
  }
  .blog-page .blog-details blockquote {
    padding: 20px;
    margin: 30px 0;
  }
  .blog-page .blog-details blockquote .icon {
    font-size: 20px;
    left: 20px;
    top: 15px;
  }
  .blog-page .blog-details .post-bottom-meta {
    display: block;
  }
  .blog-page .blog-details .post-bottom-meta .post-tags {
    margin-bottom: 1rem;
  }
  .blog-page .blog-details .post-bottom-meta .post-share {
    text-align: left;
  }
  .single-post-pagination {
    display: block;
  }
  .single-post-pagination .next-post {
    text-align: left;
  }
  .single-post-pagination .post-pagination-center-grid {
    display: none;
  }
  .blog-page .blog-details .comment-area .all-comments article .comment-author {
    width: 55px;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-author
    img {
    max-width: 50px;
    border-radius: 50%;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-details {
    overflow: hidden;
    padding-left: 10px;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-details
    .comment-meta
    .comment-left-meta
    .author-name {
    font-size: 14px;
    line-height: 13px;
    margin-bottom: 0;
  }
  .blog-page .blog-details .comment-area .all-comments .comment-list ul li ul {
    padding-left: 30px;
    list-style: none;
  }

  .modal-body {
    padding: 2.5em 1em;
  }
}

@media (max-width: 600px) {
  .hero-header {
    min-height: 400px;
  }
  .signup-frm .btn {
    bottom: 4px;
    position: relative;
    right: 0;
    border-width: 0;
    height: 52px;
    margin-top: 12px;
    width: 100%;
  }
  ul.our-team-profile li a {
    width: 34px;
    height: 34px;
    line-height: 33px;
  }
  .rt-content-box ul.our-team-profile li {
    padding: 0;
    margin-right: 6px;
  }
  .lt-img-box {
    display: table;
    width: 105px;
    float: none;
    margin: 0 auto;
  }
  .rt-content-box {
    display: table;
    margin-left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .jumbo-banner .btn-trans-video,
  .hero-banner .btn-trans-video {
    display: block;
    margin-top: 10px;
    margin-left: 0 !important;
  }
  .error-page h2 {
    font-size: 50px;
  }
  .freelancer-thumb {
    float: none;
    margin-bottom: 1rem;
  }
  .freelancer-caption {
    display: block;
    margin-left: 0;
  }

  .hero-search-wrap {
    padding: 45px 20px 30px;
  }
  .header.nav-left-side ul.attributes li a {
    padding: 22px 8px;
    font-size: 12px;
  }

  .avl-features li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .agency-list .agency-content {
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .agency-list .agency-name h4 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .agency-list .social-icons li {
    display: inline-block;
    margin-right: 7px;
  }
  .btn.btn-agent-add {
    display: none;
  }
}

@media (max-width: 500px) {
  .single-widget{
    margin-top: -57px;
    padding: 25px 20px;
  }
  .list-layout .property-listing.property-1 .listing-name {
    font-size: 14px;
  }
  .elgio_filter .btn {
    height: 40px;
    display: inline-flex;
    align-items: center;
    background: #e8ebf1;
    color: #415073;
    font-weight: 600;
    font-size: 12px;
    border-radius: 0.2rem;
    padding: 0 12px;
  }
  .listing-card-info-price {
    font-size: 17px;
  }
  .fs-inner-container .property-listing.property-1 .listing-location {
    font-size: 12px;
  }
  .list-layout .listing-detail-btn .more-btn {
    border-radius: 50px !important;
    padding: 5px 14px !important;
  }
  .prt-detail-title-desc h3 {
    font-size: 18px;
    line-height: 1.4;
  }
  ul.deatil_features li {
    width: 100%;
    flex: 0 0 100%;
  }
  .author-review article .article_comments_thumb {
    float: left;
    width: 60px;
  }
  .author-review article .article_comments_thumb img {
    max-width: 60px;
    border-radius: 50%;
  }
  .author-review article .comment-details {
    overflow: hidden;
    padding-left: 20px;
  }
  .author-review article .comment-details .comment-text p {
    font-size: 13px;
  }
  .nearby_header_first h5 {
    margin: 0;
    font-size: 13px;
  }
  .single-post-item .post-details .post-title {
    font-size: 20px;
    line-height: 1.3;
    margin: 5px 0 0;
  }
  .single-post-item .post-details p,
  .single-post-item .post-details .text {
    font-size: 15px;
    margin: 15px 0 0;
  }
}

/*====================================
   Bottom To top Scroll
  =====================================*/
#back2Top {
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  z-index: 999;
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 20px;
  text-align: center;
  font-size: 15px;
  border-radius: 4px;
  text-decoration: none;
  background: #333c56;
  color: #ffffff;
}
#back2Top:hover {
  background-color: #ffffff;
  color: #333c56;
}

.style-switcher {
  position: fixed;
  top: 35%;
  transition: 0.4s ease-in-out;
  background-color: #fff;
  left: -220px;
  width: 220px;
  padding: 0;
  z-index: 1010;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.style-switcher .css-trigger {
  position: absolute;
  top: 37%;
  transform: translateY(-50%);
  right: -50px;
  width: 50px;
  background-color: #2d4767;
  color: #fff;
  border-radius: 0px 3px 3px 0px;
  text-align: center;
  line-height: 55px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
}
.style-switcher .css-trigger a {
  color: #ffffff;
}
.style-switcher.active {
  left: 0;
}
ul#themecolors {
  margin: 0;
  padding: 15px 0;
  text-align: center;
  background: #334e6f;
}
ul#themecolors li {
  list-style: none;
  padding: 5px;
  display: inline-block;
}
ul#themecolors li a {
  width: 35px;
  height: 35px;
  background: #f4f5f7;
  display: inline-block;
  border-radius: 50%;
  font-size: 0;
  border: 3px solid rgba(255, 255, 255, 1);
}
a.default-theme {
  background: #fb7618 !important;
}
a.blue-theme {
  background: #3a5bbc !important;
}
a.green-theme {
  background: #28b446 !important;
}
a.red-theme {
  background: #d32e2a !important;
}
a.purple-theme {
  background: #9886ec !important;
}
a.yellow-theme {
  background: #fbbb00 !important;
}
a.oceangreen-theme {
  background: #00a877 !important;
}
a.goodred-theme {
  background: #f40c43 !important;
}
a.goodgreen-theme {
  background: #19b521 !important;
}
a.blue2-theme {
  background: #0176ff !important;
}

@media only screen and (max-width: 767px) {
  .singles-dashboard-list {
    display: block;
    width: 100%;
  }
  .sd-list-left {
    max-width: 100%;
  }
  .sd-list-right {
    padding: 1rem 1rem;
  }
}
